import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Lantern, Lens, Driver } from '../../ldt-generator-api-orangetek/src/types';
import './App.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://ldt-generator-backend-o-fxhvhzazghhghgdh.uksouth-01.azurewebsites.net/api';

const App: React.FC = () => {
  const [lanterns, setLanterns] = useState<Lantern[]>([]);
  const [lenses, setLenses] = useState<Lens[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [ledSet, setLedSet] = useState<string[]>([]);
  const [selectedLantern, setSelectedLantern] = useState<string | null>(null);
  const [selectedLens, setSelectedLens] = useState<string | null>(null);
  const [selectedDriver, setSelectedDriver] = useState<string | null>(null);
  const [selectedCCT, setSelectedCCT] = useState<number | null>(null);
  const [selectedLEDSet, setSelectedLEDSet] = useState<number | null>(null);
  const [lumenOutput, setLumenOutput] = useState<number>(50);
  const [desiredPowerOutput, setDesiredPowerOutput] = useState<number>(20);
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [fileData, setFileData] = useState<string[] | undefined>(undefined);
  const [gldfFileName, setGldfFileName] = useState<string | undefined>(undefined);
  const [gldfFileData, setGldfFileData] = useState<Blob | undefined>(undefined);
  const [tm33FileName, setTm33FileName] = useState<string | undefined>(undefined);
  const [tm33FileData, setTm33FileData] = useState<string[] | undefined>(undefined);
  const [length, setSelectedLength] = useState<number | undefined>(undefined);
  const [width, setSelectedWidth] = useState<number | undefined>(undefined);
  const [height, setSelectedHeight] = useState<number | undefined>(undefined);
  const [dimensions, setDimensions] = useState<boolean>(false);
  const [maxPower, setMaxPower] = useState<number>(110)
  const [minPower, setMinPower] = useState<number>(20)

  const handleDesiredPowerOutputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPower = Number(event.target.value);
    setDesiredPowerOutput(newPower)
  };

  const downloadFile = (fileName: string, data: string[]) => {
    const blob = new Blob([data.join('\n')], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const downloadGLDF = (fileName: string, data: Blob) => {
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    axios.get(`${API_BASE_URL}/lanterns`)
      .then(response => setLanterns(response.data))
      .catch(error => console.error('Error fetching lanterns:', error));
  }, []);

  useEffect(() => {
    if (selectedLantern) {
      axios.get(`${API_BASE_URL}/lenses_for_lantern`, { params: { lantern: selectedLantern } })
        .then(response => setLenses(response.data))
        .catch(error => console.error('Error fetching lenses for lantern:', error));

      axios.get(`${API_BASE_URL}/get_led_set`, { params: { lantern: selectedLantern } })
        .then(response => setLedSet(response.data))
        .catch(error => console.error('Error fetching leds for lantern:', error));
    } else {
      setLedSet([]);
      setSelectedLEDSet(null);
      setLenses([]);
      setSelectedLens(null);
    }
  }, [selectedLantern]);

  useEffect(() => {
    if (selectedLens) {
      axios.get(`${API_BASE_URL}/drivers_for_lantern_and_lens`, {
        params: { lantern: selectedLantern, lens: selectedLens }
      })
        .then(response => setDrivers(response.data))
        .catch(error => console.error('Error fetching drivers for lantern and lens:', error));
    } else {
      setDrivers([]);
      setSelectedDriver(null);
    }
  }, [selectedLens, selectedLantern]);

  useEffect(() => {
    if ((height !== 0 || undefined) && (width !== 0 || undefined) && (length !== 0 || undefined)) {
      setDimensions(true);
    } else {
      setDimensions(false);
    }
  }, [height, width, length]);

  useEffect(() => {
    if (selectedLantern && selectedLens && selectedDriver && selectedCCT && desiredPowerOutput && selectedLEDSet) {
      axios.get(`${API_BASE_URL}/generate_files`, {
        params: {
          lantern: selectedLantern,
          lens: selectedLens,
          driver: selectedDriver,
          power: desiredPowerOutput,
          cct: selectedCCT,
          ledSet: selectedLEDSet,
          length: length,
          width: width,
          height: height
        },
      })
        .then((response: any) => {
          setFileName(response.data.ldtFileName);
          setFileData(response.data.ldtData);
          setLumenOutput(response.data.targetFlux);
          setTm33FileName(response.data.tm33FileName);
          setTm33FileData(response.data.tm33Data);
          setGldfFileName(response.data.ldtFileName.split('.ldt')[0] + '.zip'); // Change file extension to .gldf for GLDF file (is .zip to see inside the file)
          axios.get(`${API_BASE_URL}/generate_gldf`, {
            params: {
              luminaireName: response.data.luminaireName,
              luminaireNumber: response.data.luminaireNumber,
              fileName: response.data.ldtFileName,
              totalFlux: response.data.targetFlux,
              typeOfLamp: response.data.typeOfLamp,
              colourTemp: response.data.colourTemp,
              wattage: response.data.wattage,
              length: length,
              width: width,
              height: height
            },
            responseType: 'blob'
          })
            .then(response => {
              setGldfFileData(response.data);
            })
            .catch(error => console.error('Error generating GLDF file:', error));
        })
        .catch(error => console.error('Error generating filename:', error));


    }
  }, [selectedLantern, selectedLens, selectedDriver, selectedCCT, desiredPowerOutput, selectedLEDSet, dimensions, length, width, height]);

  useEffect(() => {
    if (selectedLantern && selectedDriver) {
      axios.get(`${API_BASE_URL}/get_power_range`, {
        params: { lantern: selectedLantern, driver: selectedDriver }
      })
        .then(response => {
          if (desiredPowerOutput > response.data.maxPower) {
            setDesiredPowerOutput(response.data.maxPower);
          } else if (desiredPowerOutput < response.data.minPower) {
            setDesiredPowerOutput(response.data.minPower);
          };
          setMinPower(response.data.minPower);
          setMaxPower(response.data.maxPower);
        })
        .catch(error => console.error('Error getting min and max power:', error));
    }
           // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLantern, selectedDriver]);

  return (
    <div className="container mt-5">
      <h1 className="mb-4">File Generator</h1>
      <div className='main-container'>
        <div className="options-container">
          <div className="mb-4">
            <p className="mb-1 fw-bold">Lantern:</p>
            <select id="lantern" onChange={e => setSelectedLantern(e.target.value)}>
              <option value="">Select Lantern</option>
              {lanterns.map(lantern => (
                <option key={lantern._id} value={lantern.name}>
                  {lantern.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <p className="mb-1 fw-bold">Lens:</p>
            <select id="lens" onChange={e => setSelectedLens(e.target.value)} disabled={!selectedLantern}>
              <option value="">Select Lens</option>
              {lenses.map(lens => (
                <option key={lens._id} value={lens.name}>
                  {lens.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <p className="mb-1 fw-bold">Driver:</p>
            <select id="driver" onChange={e => setSelectedDriver(e.target.value)} disabled={!selectedLens}>
              <option value="">Select Driver</option>
              {drivers.map(driver => (
                <option key={driver._id} value={driver.name}>
                  {driver.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <p className="mb-1 fw-bold">Number of LEDs:</p>
            <select id="leds" onChange={e => setSelectedLEDSet(Number(e.target.value))} disabled={!selectedDriver}>
              <option value="">Select Number of LEDs</option>
              {ledSet.map(led => (
                <option key={led} value={led}>
                  {led}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <p className="mb-1 fw-bold">Dimensions:</p>
            <input
              title='Length (mm)'
              type="number"
              className="form-control mb-2"
              placeholder="Length (mm)"
              style={{ width: 200 }}
              onChange={e => setSelectedLength(Number(e.target.value))}
              disabled={!selectedDriver}
            />
            <input
              type="number"
              className="form-control mb-2"
              placeholder="Width (mm)"
              style={{ width: 200 }}
              onChange={e => setSelectedWidth(Number(e.target.value))}
              disabled={!selectedDriver}
            />
            <input
              type="number"
              className="form-control mb-2"
              placeholder="Height (mm)"
              style={{ width: 200 }}
              onChange={e => setSelectedHeight(Number(e.target.value))}
              disabled={!selectedDriver}
            />
            <p className="mb-1 fw-bold">Total Volume: </p>
            {dimensions && (
              <p className="mb-1">{(length ?? 0) * (width ?? 0) * (height ?? 0)} mm³</p>
            )}
          </div>
          <div className="mb-4">
            <p className="mb-1 fw-bold">Colour Temperature:</p>
            <select id="cct" onChange={e => setSelectedCCT(Number(e.target.value))} disabled={!selectedDriver}>
              <option value="">Select Colour Temperature</option>
              {[2200, 3000, 4000].map(temp => (
                <option key={temp} value={temp}>
                  {temp}K
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <p className="mb-1"><span className="fw-bold">Desired Power Output: </span>{desiredPowerOutput} Watts</p>
            <input
              type="range"
              className="form-range"
              style={{
                width: 200,
              }}
              id="slider"
              min={minPower}
              max={maxPower}
              value={desiredPowerOutput}
              onChange={handleDesiredPowerOutputChange}
            />
            <p className="mb-1"><span className="fw-bold">Desired Lumen Output: </span>{lumenOutput} Lumens</p>
          </div>
        </div>
        <div className='download-container'>
          <div>
            <p className="fw-bold">File Download:</p>
            {fileName && fileData && tm33FileName && tm33FileData && (
              <span>
                <p className="link" onClick={() => downloadFile(fileName, fileData)}>
                  {fileName}
                </p>
                <p className="link" onClick={() => downloadFile(tm33FileName, tm33FileData)}>
                  {tm33FileName}
                </p>
              </span>
            )}
          </div>
          <div>
            <p className="fw-bold">GLDF Download:</p>
            {gldfFileName && gldfFileData && (
              <p className="link" onClick={() => downloadGLDF(gldfFileName, gldfFileData)}>
                {gldfFileName}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;